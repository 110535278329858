import * as React from "react";
import Layout from "../components/layout";
import RSVPForm from "../components/rsvp-form";
import * as RsvpStyles from "./rsvp.module.css";

import navImage from "../images/nav-image.png";

const RSVPPage = () => {
    return (
        <Layout>
            <div className={RsvpStyles.title}>
                RSVP
            </div>
            <div className={RsvpStyles.container}>
                <div className={RsvpStyles.illustration}>
                    <img src={navImage} />
                </div>
            </div>
            <div className={RsvpStyles.container}>
                <RSVPForm />
            </div>
        </Layout>
    )
};

export default RSVPPage;
