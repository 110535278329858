import * as React from "react"
import * as rsvpStyles from "./rsvp-form.module.css";

const RSVPForm = () => {
    return (
        <form className={rsvpStyles.form} name="rsvp" method="POST" data-netlify="true" action="/confirmation-rsvp">
            <div className={rsvpStyles.header}>
                Please send us your RSVPs by 1st April.
                <br/><br />
                If you're coming with someone who hasn't received the email,
                please also fill out this form on their behalf, or share it with them.
                <br/><br />
                As always, shoot us a message if any questions. Thanks!
            </div>
            <input type="hidden" name="form-name" value="rsvp" />
            <p>
                <label htmlFor="first-name">First name</label>
                <input required id="first-name" type="text" name="firstName" />
            </p>
            <p>
                <label htmlFor="last-name">Last name</label>
                <input required id="last-name" type="text" name="lastName" />
            </p>
            <p>
                <label htmlFor="dietary">
                    Any dietary requirements?
                </label>
                <input id="dietary" type="text" name="dietary" />
            </p>
            <p>
                <label htmlFor="station-transport">
                    Will you need transport from Billingshurst station to the church?
                </label>
                <select name="stationTransport" id="station-transport">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </select>
            </p>
            <p>
                <label htmlFor="church-transport">
                    Will you need transport from the church to the reception?
                </label>
                <select name="churchTransport" id="church-transport">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </select>
            </p>
            <p>
                <label htmlFor="bbq">
                    Will you be around for the bbq on Sunday?
                </label>
                <select name="bbq" id="bbq">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </select>
            </p>
            <p>
                <label htmlFor="bbqTransport">
                    And will you need transport from the bbq back to Billingshurst station?
                </label>
                <select name="bbqTransport" id="bbqTransport">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </select>
            </p>
            <div>
                <button type="submit">Send</button>
            </div>
        </form>
    );
};

export default RSVPForm;
